import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CustomSnackbarComponent } from '@shared/components/custom-snackbar/custom.component';
import { filter } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    private snackBarDisplayed = false; 

    constructor(
        private swUpdate: SwUpdate,
        private resolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.swUpdate.versionUpdates
            .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
            .subscribe(() => {
                if (!this.snackBarDisplayed) {
                    this.showCustomSnackbar('Nueva Versión disponible');
                    this.snackBarDisplayed = true; 
                }
            });
    }

    showCustomSnackbar(message: string): void {
        this.viewContainerRef.clear();

        const factory = this.resolver.resolveComponentFactory(CustomSnackbarComponent);
        const componentRef = this.viewContainerRef.createComponent(factory);

        componentRef.instance.message = message;

        componentRef.instance.reload.subscribe(() => {
            this.swUpdate.activateUpdate().then(() => {
                window.location.reload();
            });
        });
    }
}
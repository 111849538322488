
export const TitleType = [
  // Inicio
  { icon: "", text: "Inicio", path: "/inicio" },
  // Complejo
  { icon:"", text: "Naciones Unidas", path:"/complejo?numero=1" },
  { icon:"", text: "Velez Sarfield", path:"/complejo?numero=2" },
  { icon:"", text: "Linares", path:"/complejo?numero=3" },
  { icon:"", text: "O'Higgins", path:"/complejo?numero=4" },
  { icon:"", text: "Lamarque N° 1032", path:"/complejo?numero=5" },
  { icon:"", text: "Lamarque N° 1062", path:"/complejo?numero=6" },
  { icon:"", text: "Teniente Ibañez", path:"/complejo?numero=7" },
  { icon:"", text: "Chacabuco", path:"/complejo?numero=8" },
  { icon:"", text: "Edificio B66 - Juan", path:"/complejo?numero=11" },
  { icon:"", text: "Alberdi", path:"/complejo?numero=13" },
  { icon:"", text: "Edificio L1", path:"/complejo?numero=14" },
  { icon:"", text: "Edificio B66", path:"/complejo?numero=15" },
  { icon:"", text: "Casa JC", path:"/complejo?numero=16" },
  { icon:"", text: "Casa HH", path:"/complejo?numero=17" },
  { icon:"", text: "Oro Urbano", path:"/complejo?numero=18" },
  { icon:"", text: "Prada Victoria", path:"/complejo?numero=20" },
  // Contratos
  { icon: "", text: "Contratos", path: "/contratos" },
  // Indices
  { icon: "", text: "Valor de Índices Histórico", path: "/indices" },
  // Expensas
  { icon: "", text: "Expensas", path: "/expensas" },
  // Disponibles
  { icon: "", text: "Disponibles", path: "/disponibles" },
  // Próximos a vencer
  { icon: "", text: "Próximo a Vencer", path: "/proximo-vencer" }
];

import { EventEmitter, Injectable, Output } from '@angular/core';
import { IAlertConfig } from '../../models/alert';

@Injectable()
export class AppAlertService {
  @Output() emitConfig = new EventEmitter<IAlertConfig>();

  success(message: string) {
    this.emitConfig.emit({
      message: message,
      type: 'success',
    });
  }

  error(message: string) {
    this.emitConfig.emit({
      message: message,
      type: 'error',
    });
  }

  info(message: string) {
    this.emitConfig.emit({
      message: message,
      type: 'info',
    });
  }

  warning(message: string) {
    console.log(message,'warning mensaje')
    this.emitConfig.emit({
      message: message,
      type: 'warning',
    });
  }
}